var exports = {};

(function (global, factory) {
  factory(exports);
})(exports, function (exports) {
  'use strict';

  var fp = typeof window !== "undefined" && window.flatpickr !== undefined ? window.flatpickr : {
    l10ns: {}
  };
  var Spanish = {
    weekdays: {
      shorthand: ["Dom", "Lun", "Mar", "Mi\xE9", "Jue", "Vie", "S\xE1b"],
      longhand: ["Domingo", "Lunes", "Martes", "Mi\xE9rcoles", "Jueves", "Viernes", "S\xE1bado"]
    },
    months: {
      shorthand: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
      longhand: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
    },
    ordinal: function () {
      return "\xBA";
    },
    firstDayOfWeek: 1,
    rangeSeparator: " a ",
    time_24hr: true
  };
  fp.l10ns.es = Spanish;
  var es = fp.l10ns;
  exports.Spanish = Spanish;
  exports.default = es;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
});

export default exports;
export const Spanish = exports.Spanish,
      __esModule = exports.__esModule;